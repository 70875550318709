import './Newsletter.scss'

const Newsletter = () => {
  return (
    <div className="Newsletter content">
      <h2 className="contentHeading">n e w s l e t t e r</h2>
      <div className="newsletterWrapper">
        <h3>AUG 11TH, 2022 ~ BIG HUGE ANNOUNCEMENT</h3>
        <p>
          If you opened this, what's good?? It's been too long! <br />
          <br />
          <strong>BIG HUGE ANNOUNCEMENT:</strong> <br />
          I'm recording my debut stand up album in Chicago, Nov 11th, 9pm and
          11pm.
          <br />
          <a
            href="https://www.eventbrite.com/e/jason-meltons-live-stand-up-comedy-album-recording-at-the-vfw-tickets-400560917487"
            target="_blank"
            rel="noopener noreferrer"
          >
            TICKETS
          </a>
          <br />
          <br />
          ^^ that's the main thing I wanted to say.
          <br />
          <br />
          I'm excited to be recording ~50 minutes of stand up comedy at the VFW
          this veterans day. I've been working on my act for years. I've toured
          it through 22 different states, performing in any club or dive bar or
          basement with a microphone. Join me and add your laughs to the digital
          record. My funny ass jokes plus your laughs living uploaded into the
          forever of the world wide web. <br />
          <br />
          Pack out this room with me. I like performing for a big crowd. <br />
          <br />
          Thanks for supporting my endeavor and thanks for all the help over the
          years. You've really really helped me and I deeply appreciate it.
        </p>
        <h4>-upcoming shows:</h4>
        <p>
          Visit my site:{' '}
          <a
            href="https://jasonmeltoncomedy.com/shows"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://jasonmeltoncomedy.com/shows
          </a>
        </p>
        <h4>-stuff you can enjoy:</h4>

        <p>
          JASON MELTON COMEDY VIDS YOUTUBE: sketches, stand up, and old Robocop
          2 podcasts
          <br />
          ==={'>'}{' '}
          <a
            href="https://www.youtube.com/channel/UCeY-WmZCX04q2mb2kOoObqQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            HERE
          </a>
          <br />
          <br />
          BAD COMEDY PODCAST: Bad jokes and shit talk as I cohost alongside Mack
          Knepper
          <br />
          ==={'>'}{' '}
          <a
            href="https://www.instagram.com/badcomedypodcast/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          ,{' '}
          <a
            href="https://www.patreon.com/badcomedy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Patreon
          </a>{' '}
          <br />
          <br />
          EXPLORATIONS W/ LESLIE TANNER: my old psychiatrist Dr. Gerald Elkhart
          guested this episode
          <br />
          ==={'>'}{' '}
          <a
            href="https://www.twitch.tv/videos/1557445876"
            target="_blank"
            rel="noopener noreferrer"
          >
            Leslie's Twitch
          </a>
          <br />
          <br />
          LOBO DEN PODCAST: I was a guest recently. <br />
          ==={'>'}{' '}
          <a
            href="https://linktr.ee/theloboden"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lots of ways to watch / listen
          </a>
          <br />
          <br />
          COCAINE MURDER JAM TWEETS: new tweets every day
          <br />
          ==={'>'}{' '}
          <a
            href="https://twitter.com/cmj69420"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cocaine Murder Jam twitter
          </a>
        </p>
      </div>
    </div>
  )
}

export default Newsletter
