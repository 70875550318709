import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NavButton from './NavButton'
import './Navbar.scss'

const navOptions = [
  // {
  //   name: 'shows',
  //   text: 's h o w s',
  //   route: '/shows',
  // },
  {
    name: 'bio',
    text: 'b i o',
    route: '/bio',
  },
  {
    name: 'special',
    text: 's p e c i a l',
    route: '/special',
  },
  {
    name: 'video',
    text: 'v i d e o',
    route: '/video',
  },
  {
    name: 'newsletter',
    text: 'n e w s l e t t e r',
    route: '/newsletter',
  },
  {
    name: 'zine',
    text: 'z i n e',
    route: '/zine',
  },
  {
    name: 'contact',
    text: 'c o n t a c t',
    route: '/contact',
  },
]

const Navbar = ({ navbarOptions }) => {
  const history = useHistory()
  const [openDropdown, setOpenDropdown] = useState(false)

  const handleClick = useCallback(
    (route) => {
      history.push(route)
      if (openDropdown) setOpenDropdown(false)
    },
    [history, openDropdown]
  )

  const dropdownClass = useCallback(() => {
    const classArr = ['dropdownOptionsWrapper']
    if (openDropdown) classArr.push('open')
    return classArr.join(' ')
  }, [openDropdown])

  return (
    <div className="Navbar">
      <div className="navButtonWrapper">
        {navOptions.map((opt, index) => {
          if (navbarOptions.includes(opt.name)) {
            return (
              <NavButton
                key={`${opt.route}-${index}`}
                option={opt}
                handleClick={handleClick}
              />
            )
          } else return null
        })}
      </div>
      <div className="navDropdownWrapper">
        <button
          className="dropdownButton"
          aria-label="dropdown"
          onClick={() => setOpenDropdown(!openDropdown)}
        />
        <div className={dropdownClass()}>
          {openDropdown &&
            navOptions.map((opt, index) => {
              if (navbarOptions.includes(opt.name)) {
                return (
                  <NavButton
                    key={`${opt}-${index}`}
                    option={opt}
                    handleClick={handleClick}
                  />
                )
              } else return null
            })}
        </div>
      </div>
    </div>
  )
}

export default Navbar
