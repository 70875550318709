import './Special.scss'

const Special = () => (
  <div className="content Special">
    <h2 className="contentHeading specialHeading">
      "Vanity Project" by Jason Melton (2023)
    </h2>
    <iframe
      width="100%"
      height="75%"
      src="https://www.youtube.com/embed/nNOr5U1nTj4"
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  </div>
)

export default Special
