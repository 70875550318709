import './Contact.scss'
import EmailButton from '../../components/EmailButton/EmailButton'
import SocialButtons from '../../components/SocialButtons/SocialButtons'

const Contact = () => (
  <div className="Contact content">
    <h2 className="contentHeading">c o n t a c t</h2>
    <div className="socialButtonWrapper">
      <h3 className="contentSubheading">email:</h3>
      <EmailButton isItalicized={false} isTextOnly />
    </div>
    <SocialButtons />
  </div>
)

export default Contact
