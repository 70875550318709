// import { useState, useCallback } from 'react'
import './ExternalImage.scss'
// import Loader from '../Loader/Loader'

const ExternalImage = ({ className, src, alt, ...props }) => {
  // const [loaded, setLoaded] = useState(false)

  // const onLoad = useCallback(() => {
  //   setLoaded(true)
  // }, [])
  return (
    <>
      <img
        className={className}
        // style={{ display: loaded ? 'block' : 'none' }}
        // onLoad={onLoad}
        src={src}
        alt={alt}
        {...props}
      />
      {/* {!loaded && (
        <div className={`${className} loaderWrapper`}>
          <Loader />
        </div>
      )} */}
    </>
  )
}

export default ExternalImage
