import Hero from '../../components/Hero/Hero'
import Navbar from '../../components/Navbar/Navbar'
// import Banner from '../../components/Banner/Banner'

const AppLayout = () => {
  const navbarOptions = ['shows', 'bio', 'special', 'video', 'contact']
  return (
    <div className="AppLayout">
      <Hero />
      <Navbar navbarOptions={navbarOptions} />
      {/* <Banner /> */}
    </div>
  )
}

export default AppLayout
