import './App.scss'
import AppLayout from './containers/AppLayout/AppLayout'
// import Shows from './containers/Shows/Shows'
import Bio from './containers/Bio/Bio'
import Newsletter from './containers/Newsletter/Newsletter'
import Video from './containers/Video/Video'
import Zine from './containers/Zine/Zine'
import Contact from './containers/Contact/Contact'
import Special from './containers/Special/Special'

import { Switch, Route, Redirect } from 'react-router-dom'

const App = () => {
  return (
    <div className="App">
      <AppLayout />
      <div className="contentWrapper">
        <Switch>
          {/* <Route path="/shows">
            <Shows />
          </Route> */}
          <Route path="/bio">
            <Bio />
          </Route>
          <Route path="/newsletter">
            <Newsletter />
          </Route>
          <Route path="/special">
            <Special />
          </Route>
          <Route path="/video">
            <Video />
          </Route>
          <Route path="/zine">
            <Zine />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Redirect to={{ pathname: '/bio' }} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default App
