import './EmailButton.scss'
import { useState, useCallback, useEffect } from 'react'

const EmailButton = ({
  isItalicized,
  isTextOnly,
  buttonClassName,
  copyMessageClassName,
}) => {
  const [copyMessage, setCopyMessage] = useState(false)

  const clearMessage = useCallback(
    (timer) => {
      setCopyMessage(false)
      clearInterval(timer)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    const timer = setInterval(() => clearMessage(timer), 3000)
  }, [clearMessage, copyMessage])

  const copyEmailToClipBoard = useCallback(() => {
    if (!copyMessage) {
      navigator.clipboard.writeText('jasonmeltoncomedy@gmail.com')
      setCopyMessage(true)
    }
  }, [copyMessage])
  return (
    <span className="emailButtonWrapper">
      <button
        className={`${
          isTextOnly ? 'emailText' : 'emailButton'
        } ${buttonClassName}`}
        onClick={copyEmailToClipBoard}
      >
        <span className="contentText">
          {isItalicized ? (
            <i>jasonmeltoncomedy@gmail.com</i>
          ) : (
            'jasonmeltoncomedy@gmail.com'
          )}
        </span>
      </button>
      {copyMessage && (
        <p className={`copyMessage ${copyMessageClassName}`}> c o p i e d</p>
      )}
    </span>
  )
}

export default EmailButton
