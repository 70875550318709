// import { useCallback } from 'react';
import "./NavButton.scss";

const NavButton = ({ option, handleClick }) => {
  const { text, route } = option;

  return (
    <button className="NavButton" onClick={() => handleClick(route)}>
      {text}
    </button>
  );
};

export default NavButton;
