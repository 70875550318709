import "./Hero.scss";
import { useHistory } from "react-router";

const Hero = () => {
  const history = useHistory();
  return (
    <>
      <button
        className="headingButton"
        aria-label="home"
        onClick={() => history.push("/shows")}
      >
        <h1 className="heading">J a s o n - m e l t o n</h1>
      </button>
      <section
        className="masthead"
        role="img"
        aria-label="Whats Good with Jason Melton"
      />
    </>
  );
};

export default Hero;
