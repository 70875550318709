import './Bio.scss'
import jasontcb from '../../assets/jason-tcb.jpeg'
import EmailButton from '../../components/EmailButton/EmailButton'
import ExternalImage from '../../components/ExternalImage/ExternalImage'

const Bio = () => {
  return (
    <div className="Bio content">
      <h2 className="contentHeading">b i o</h2>
      <div className="imgWrapper">
        <ExternalImage
          className="jasonImg tcb"
          src={jasontcb}
          alt="jason in robe"
        />
        <p className="contentText bioText tcb">
          Jason Melton is a Chicago-based comedian that performs at clubs and
          bars all over the country. His comedy has been described as charming
          and funny. Check out his sketch show Cocaine Murder Jam or the Bad
          Comedy Podcast.
          <EmailButton
            buttonClassName="bioEmailButton"
            copyMessageClassName="bioCopyMessageClassName"
            isTextOnly={false}
            isItalicized={false}
          />
        </p>
      </div>
      <h2 className="contentHeading">c l i p s</h2>
      <div className="videoWrapper">
        <iframe
          title="stand up clip humboldt jungle"
          src="https://www.youtube.com/embed/28QB7fGfkzk"
          className="videoClip"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
      <div className="videoWrapper">
        <iframe
          title="stand up clip cysk"
          src="https://www.youtube.com/embed/RHi8SbOxnAo"
          className="videoClip"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
      <div className="videoWrapper">
        <iframe
          title="stand up clip go bananas"
          src="https://player.vimeo.com/video/331421832"
          className="videoClip"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default Bio
