import VideoCard from '../../components/VideoCard/VideoCard'
import videoData from '../../data/videos.json'
import { useState } from 'react'

import './Video.scss'

const Video = () => {
  // const [selectedVid, setSelectedVid] = useState('')

  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0)
  const selectedSectionData = videoData[selectedSectionIndex]

  return (
    <div className="Video wideContent">
      <h2 className="contentHeading">v i d e o</h2>

      <div className="videoSectionSelectorWrapper">
        {videoData.map((videoSection, index) => (
          <button
            key={`videoSelectorButton-${index}`}
            className={`videoSelectorButton ${
              selectedSectionIndex === index ? 'selected' : ''
            }`}
            onClick={() => setSelectedSectionIndex(index)}
          >
            {videoSection.name}
          </button>
        ))}
      </div>

      {selectedSectionData.videos.length > 0 && (
        <div key={`${selectedSectionData}-${selectedSectionIndex}`}>
          <h3 className="contentHeading videoSectionHeading">
            {selectedSectionData.name}
          </h3>
          <div className="videoSectionWrapper">
            {selectedSectionData.videos.map((videoData, vdIndex) => (
              <VideoCard
                key={`${selectedSectionData}-${vdIndex}`}
                // videoClassName={
                //   selectedVid === `${videoData.index}-${videoData.title}`
                //     ? 'selectedVideo'
                //     : ''
                // }
                // onClick={() =>
                //   setSelectedVid(`${videoData.index}-${videoData.title}`)
                // }
                {...videoData}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Video
