import socialData from './socialLinks.json'
import './SocialButtons.scss'

const SocialButtons = ({ isInheritBackgroundColor = false }) => (
  <div
    className={
      isInheritBackgroundColor
        ? 'socialButtonWrapper inheritBackgroundColor'
        : 'socialButtonWrapper'
    }
  >
    {socialData.map((item) => (
      <a
        {...item}
        className={item.className}
        key={item.name}
        aria-label={item.name}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i aria-hidden="true"></i>
      </a>
    ))}
  </div>
)

export default SocialButtons
