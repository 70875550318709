import "./VideoCard.scss";
// import { useState, useCallback } from 'react'
// import Loader from '../Loader/Loader';

const VideoCard = ({ index, link, title, videoClassName = '', onClick }) => {
  // const [isLoading, setIsLoading] = useState(true)
  // const turnOffLoader = useCallback(() => {
  //   if (isLoading) {
  //     setIsLoading(false)
  //   }
  // }, [isLoading])
  return (
    <div onClick={onClick} className="videoCard">
      {title && <h3 className="videoTitle">{title}</h3>}
      {/* {isLoading && <Loader key={`loader-${index}-${title}`} />} */}
      <iframe
        // onLoad={turnOffLoader}
        key={`${link}-${index}`}
        title={link}
        src={link}
        // width="100%"
        // height="400"
        className={`embeddedVideo ${videoClassName}`}
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default VideoCard;
